import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

// import { deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';
import { API_ADMIN, API_APP, API_HOST, API_URL, host } from "helpers/api_helper";
import { __formatDate } from "utils/function";

function RedeemManagement() {
    const context = useContext(AdminContext);
    const { orders, GetAllOrders, MobileHost } = context;

    const [Data, setData] = useState([])
    const GetData = async () => {
        try {
            let response = await axios
                .get(
                    // `${"http://192.168.29.19/api/v1/app"}/redeem_history`
                    `${API_ADMIN}/redeem_history`
                    // {
                    //   headers: {
                    //     Authorization: localStorage.getItem("token"),
                    //   }
                    // }
                )
                .then(response => {
                    // console.log("res", response)
                    if (response.status === 200) {
                        console.log("history", response)
                        setData(response?.data?.redeemHistory)
                    }
                })
                .catch(err => {
                    console.log("err", err)
                    if (err)
                        toast(err, {
                            type: "error",
                            autoClose: 3000,
                            theme: "light",
                        })
                })
        } catch (error) {
            console.log(error, "catch error")
        }
    }

    useEffect(() => {
        GetData()
    }, []);

    // port
    const host = process.env.REACT_APP_HOST
    //meta title
    document.title = "Redeem History";

    const [modal, setModal] = useState(false);


    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
        status: '',
    })
    const {
        isLoading,
        delete_id,
    } = state

    // image state
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImagechange = (e) => {
        setImage(e.target.files[0]);
        const file = e.target.files[0];

        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            // Additional logic if needed
        }
    }
    const handleRemoveImage = () => {
        setSelectedImage(null);
        setImage(null)
    };


    const updateState = data => setState(state => ({ ...state, ...data }))

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const handleEditClick = arg => {
        // console.log(arg, 'arg')
        toggle();
        setIsEdit(true)
        // const data = arg;
        setFormState({
            editId: arg._id,
            // category: arg.category,   
            status: arg.status,
        })
        updateState({
            editId: arg._id,
            // category: arg.category,   
            status: arg.status,
        })
        // setIsEdit(true);
        toggle();
    };

    const columns = useMemo(
        () => [
            // {
            //     Header: 'No',
            //     accessor: (row, index) => index + 1, // Auto-incremented number
            //     Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
            //     filterable: false,
            // },
            {
                Header: 'UserName',
                accessor: 'username',
                filterable: true,
            },
            {
                Header: 'Tree',
                accessor: 'tree_image',
                filterable: true,
                Cell: ({ cell: { value } }) => {
                    // console.log(value, 'icon');
                    return <ImageCell cell={{ value }} />;
                },
            },
            // {
            //     Header: 'Tree Inage',
            //     accessor: 'tree_image',
            //     Cell: ({ value }) => {
            //         console.log(value, 'tree')
            //         // <img
            //         //   src="https://snap-booster.onrender.com/uploads/icon/icon-1707124734166.svg"
            //         //   alt="Icon" style={{ width: '30px', height: '30px' }}
            //         // />
            //         // <>
            //         //     {value ? (
            //         //         <img
            //         //             src={`${API_URL}/${value}`}
            //         //             // src={`http://192.168.1.13/uploads//trees//profile-1719837070594.jpeg`}
            //         //             alt="userpic"
            //         //             style={{ width: '50px', height: '50px' }}
            //         //         />
            //         //     ) : (
            //         //         <span>No Icon</span>
            //         //         // <img
            //         //         //   src="https://socialbooster-prod.s3.eu-west-2.amazonaws.com/img/insta.svg"
            //         //         //   alt="Icon" style={{ width: '30px', height: '30px' }}
            //         //         // />
            //         //     )}
            //         // </>

            //     },
            //     filterable: false,
            // },
            {
                Header: 'Full Name',
                accessor: 'full_name',
                filterable: true,
            },
            {
                Header: 'Redemption Type',
                accessor: 'redemptionType',
                filterable: true,
            },
            {
                Header: 'Tree Type',
                accessor: 'treeType',
                filterable: true,
            },
            {
                Header: 'Date',
                accessor: 'date',
                filterable: true,
                Cell: ({ value }) => {
                    const data = __formatDate(value)
                    return data;
                }
            },
            {
                Header: 'Number Of Trees',
                accessor: 'numberOfTrees',
                filterable: true,
            },
            // {
            //     Header: 'Updated At',
            //     accessor: 'updatedAt',
            //     filterable: true,
            //     Cell: ({ value }) => {
            //         const data = __formatDate(value)
            //         return data;
            //     }
            // },
            {
                Header: 'Action',
                accessor: '_id',
                disableFilters: true,
                Cell: (cellProps) => {
                    const data = cellProps?.data?.find(elem => elem._id === cellProps.value)
                    console.log(data, 'data')
                    console.log(data._id)
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link to="#" className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                ViewUncontrolledTooltip
                            </UncontrolledTooltip> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    // onClick={() => {
                                    //     // const categoryData = cellProps.row.original;
                                    //     // handleEditClick(categoryData);
                                    // }}
                                    onClick={() => {
                                        setIsEdit(true)
                                        // toggle();
                                        setModal(true)
                                        handleEditClick(data);
                                        setTreeId(data._id)
                                        setUserId(data.user_id)

                                        setFormState({
                                            editId: data._id,
                                            status: data.status,
                                        })
                                        setEditId(data._id)
                                        // updateState({
                                        //     isEdit: true,
                                        //     // edit: data,
                                        //     service: data.service, packageName: data.packageName,
                                        // })
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    const [formState, setFormState] = useState({
        editId: '',
        status: '',
    });

    const [validationErrors, setValidationErrors] = useState({});

    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);

    const [treeId, setTreeId] = useState(null);
    const [userId, setUserId] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('tree_image', image);
            formData.append('treeId', treeId);

            const response = await axios.post(`${API_ADMIN}/upload_tree_image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // Authorization: localStorage.getItem("token"),
                },
            });
            if (response.data.success === true) {
                GetData()
                toast(`Successfully Added`, {
                    type: 'success',
                });
                setTreeId(null)
                setUserId(null)
                setSelectedImage(null)
                setImage(null)
                toggle();
                setIsEdit(false);
                setEditId(null);
            } else {
                toast(`Failed to Upload`, {
                    type: 'error',
                });
            }
        } catch (error) {
            console.error(`Error`);
            toast(`Error Uploading `, {
                type: 'error',
            });
        }
    };

    return (
        <React.Fragment>
            {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteService}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        // title="Redeem History"
                        breadcrumbItem="Redeem History"
                    />
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            {/* <h5 className="mb-0 card-title flex-grow-1">Redeem History</h5> */}
                                            {/* <div className="flex-shrink-0">
                                                <Link to="#"
                                                    onClick={() => {
                                                        // console.log('add click')
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Package
                                                </Link>
                                                <Link to="" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                                <UncontrolledDropdown className="dropdown d-inline-block me-1">

                                                </UncontrolledDropdown>
                                            </div> */}
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={Data}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            // handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={10}
                                        />
                                        {Data?.length == 0 && !isLoading && (
                                            <div className="p-5">
                                                <div className="text-center ">
                                                    <i
                                                        className="bx bx-search"
                                                        style={{ fontSize: "30px" }}
                                                    ></i>
                                                </div>
                                                <div className="text-center mt-3">No Users Found</div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {isEdit == true ? "Edit Order  Status" : "Add Package"} */}
                            Upload Tree Image
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                // onSubmit={(e) => {
                                //     e.preventDefault();
                                //     console.log("object")
                                //     validation.handleSubmit();
                                //     return false;
                                // }}
                                onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label">Select Image</Label>
                                            <Input
                                                name="tree_image"
                                                type="file"
                                                placeholder="Select Image"
                                                onChange={(e) => { handleImagechange(e); }}
                                            />

                                            {selectedImage && (
                                                <div className="mt-2 d-flex align-items-center">
                                                    <img
                                                        src={selectedImage}
                                                        alt="Selected image"
                                                        style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline btn-sm"
                                                        onClick={handleRemoveImage}
                                                        style={{ fontSize: '25px' }}
                                                    >
                                                        {/* <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                                            <i className="mdi mdi-trash-can-outline"></i>
                                                        </div> */}
                                                        <button type="button"
                                                            className="btn-close"></button>
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        {/* <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                value={formState.status}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, status: e.target.value })
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                <option value="Order Placed">Order Placed</option>
                                                <option value="Processing">Processing</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Refunded">Refunded</option>
                                            </Input>
                                            {validationErrors.status && (
                                                <div className="text-danger">{validationErrors.status}</div>
                                            )}
                                        </div> */}


                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            // onClick={onaddPackage()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment >
    );
}

const ImageCell = ({ cell: { value } }) => {
    // console.log(value, 'category icon');
    return (
        <>
            {value ? (
                <img
                    src={`${API_HOST}/${value}`}
                    alt="Tree"
                    style={{ width: '100px', height: '80px', borderRadius: 10 }}
                    loading="lazy"
                />
            ) : (
                <span>No Image</span>
                // <img
                //     src="https://snap-booster.onrender.com/uploads/icon/icon-1707124734166.svg"
                //     alt="Icon" style={{ width: '30px', height: '30px' }}
                // />
            )}
        </>
    );
};

export default RedeemManagement;