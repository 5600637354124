import React, { useContext } from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import SuperAdmin from "../pages/Dashboard/SuperAdmin"


import LeaderManagement from "pages/StepGreen/LeaderBoard"
import RunningLeaderManagement from "pages/StepGreen/RunningLeaderBoard"
import RedeemManagement from "pages/StepGreen/RedeemManagement"


const authProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/manageAllUsers", component: <SuperAdmin /> },

  { path: "/walkingLeaders", component: <LeaderManagement /> },
  { path: "/runningLeaders", component: <RunningLeaderManagement /> },
  { path: "/redeemManagement", component: <RedeemManagement /> },


  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },

  // {
  //   path: "*",
  //   exact: true,
  //   component: (
  //     <Navigate
  //       to={localStorage.getItem("T") == "O" ? "/home/dashboard" : "/dashboard"}
  //     />
  //   ),
  // },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  //  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  { path: "/Recoverpw", component: <Recoverpw /> },
  // { path: "/dashboard", component: <Dashboard /> },

  // landing page
  { path: "/", component: <Login /> },
]

export { publicRoutes, authProtectedRoutes }

