import React, { useContext, useEffect } from "react";
import {
  Container, Card, CardBody, Button, Row, Col,
} from "reactstrap";
import AdminContext from 'context/adminContext';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
const Dashboard = props => {

  //meta title
  document.title = "Dashboard | StepGreen";

  // context 
  const context = useContext(AdminContext);
  const { UserCount, CountUser } = context;
  useEffect(() => {
    UserCount();
  }, [])
  const reports = [
    {
      title: 'Total User',
      //  iconClass: "bx-copy-alt",.
      iconClass: "bx bx-user",
      description: [CountUser]
    },
    // { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    // {
    //   title: "Average Price", iconClass: "bx-purchase-tag-alt", description: "$16.2",
    // },
  ];


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Home")}
          />
          <Row>
            <Col xl="4">
              <WelcomeComp />
              {/* <MonthlyEarning /> */}
            </Col>
            <Col xl='8'>
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>


          {/* <Card sm={12} lg={6} className="continer">
            <CardBody>
              <Button className="text-center" > <a href="http://localhost:3016/broadcast?id=123&name=Broadcaster"> Create Broadcast</a></Button>


            </CardBody>
          </Card> */}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(Dashboard);
