import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
// const API_URL = "http://localhost:8001/api/v2";
// export const API_URL = "http://www.snapbooster.us:8001";


export const host = process.env.REACT_APP_HOST;
export const API_URL = process.env.REACT_APP_API_URL;
// export const API_URL = 'http://192.168.29.19';
// export const API_APP = 'http://192.168.29.19/api/v1/app'

export const API_ADMIN = "https://stepgreen.in:6001/api/v1/admin";
export const API_APP = 'https://stepgreen.in:6001/api/v1/app'
export const API_HOST = 'https://stepgreen.in:6001'

// export const API_ADMIN = "http://192.168.29.19/api/v1/admin";
// export const API_APP = 'http://192.168.29.19/api/v1/app'
// export const API_HOST = 'http://192.168.29.19'


// export const API_APP = process.env.REACT_APP_API_APP

const BaseURL = process.env.REACT_APP_HOST;

// http://localhost:8001/api/v2/
const axiosApi = axios.create({
  baseURL: BaseURL,
});


axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
