import React, { useEffect, useMemo, useState, useContext } from "react";
import "./invoice.css"
import {
  Button,
  Card, CardBody, Col,
  Container, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback,
  Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledDropdown
} from "reactstrap";

import Select from 'react-select';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AdminContext from 'context/adminContext';

//i18n
import { withTranslation } from "react-i18next";
import { Form, Link } from "react-router-dom";
import TableContainer from "components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import AddModal from "./AddModal";
import { API_HOST, API_URL } from "helpers/api_helper";

const SuperAdmin = props => {
  //meta title
  document.title = "Manage Users | StepGreen";
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const context = useContext(AdminContext);
  const { GetAllUser, allUser } = context;
  // console.log(allUser, "all")

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {

    },

    onSubmit: (values) => {

    },
  });

  useEffect(() => {
    GetAllUser();
  }, [])

  // const columns = useMemo(
  //   () => [

  //     {
  //       Header: 'UserName',
  //       filterable: true,
  //       accessor: 'username',
  //       //   Cell: ({ value }) => {
  //       //     return value === true ? 'Admin' : 'User';
  //       //   }
  //     },
  //     {
  //       Header: 'UserId',
  //       filterable: true,
  //       accessor: '_id'
  //     },
  //     {
  //       Header: 'Email',
  //       filterable: true,
  //       accessor: 'email'
  //     },
  //     // {
  //     //   Header: 'Role',
  //     //   filterable: true,
  //     //   accessor: 'isAdmin',
  //     //   Cell: ({ value }) => {
  //     //     return value === true ? 'Admin' : 'User';
  //     //   }
  //     // },
  //     // {
  //     //   Header: 'Verification',
  //     //   accessor: 'verification',
  //     //   filterable: true,
  //     //   Cell: ({ value }) => {
  //     //     return value === true ? 'Verified' : 'Not Verified';
  //     //   }
  //     // },
  //     {
  //       Header: 'Joining date',
  //       accessor: 'createdAt',
  //       filterable: true,
  //       Cell: ({ value }) => {
  //         const localDate = new Date(value).toLocaleString();
  //         return localDate;
  //       }
  //     },
  //   ], []
  // );

  const columns = useMemo(() => [
  // {
  //   Header: 'Profile',
  //   accessor: 'userInfo.profile',
  //   Cell: ({ value }) => {
  //     // <img
  //     //   src="https://snap-booster.onrender.com/uploads/icon/icon-1707124734166.svg"
  //     //   alt="Icon" style={{ width: '30px', height: '30px' }}
  //     // />
  //     <>
  //       {value ? (
  //         <img
  //           src={`${API_URL}/${value}`}
  //           // src={`http://192.168.29.19/uploads//users//profile-1719837070594.jpeg`
  //           alt="userpic"
  //           style={{ width: '50px', height: '50px' }}
  //         />
  //       ) : (
  //         <span>No Icon</span>
  //         // <img
  //         //   src="https://socialbooster-prod.s3.eu-west-2.amazonaws.com/img/insta.svg"
  //         //   alt="Icon" style={{ width: '30px', height: '30px' }}
  //         // />
  //       )}
  //     </>

    //   },
    //   filterable: false,
    // },
    {
      Header: 'Profile',
      accessor: 'userInfo.profile',
      filterable: true,
      Cell: ({ cell: { value } }) => {
        // console.log(value, 'icon');
        return <ImageCell cell={{ value }} />;
      },
    },
    {
      Header: 'UserId',
      accessor: '_id',
      filterable: true,
    },
    {
      Header: 'UserName',
      accessor: 'userInfo.username',
      filterable: true,
    },
    {
      Header: 'Full Name',
      accessor: 'userInfo.full_name',
      filterable: true,
    },
    {
      Header: 'Email',
      accessor: 'userInfo.email',
      filterable: true,
    },
    {
      Header: 'Gender',
      accessor: 'userInfo.gender',
      filterable: true,
    },
    {
      Header: 'City',
      accessor: 'userInfo.city',
      filterable: true,
    },
    {
      Header: 'State',
      accessor: 'userInfo.state',
      filterable: true,
    },
    {
      Header: 'Organization',
      accessor: 'userInfo.organization',
      filterable: true,
    },
    {
      Header: 'Mobile No',
      accessor: 'mobile_no',
      filterable: true,
    },
    // {
    //   Header: 'Is Registered',
    //   accessor: 'isRegistred',
    //   filterable: true,
    //   Cell: ({ value }) => (value ? 'Yes' : 'No'),
    // },
    {
      Header: 'Total Steps',
      accessor: 'totalSteps',
      filterable: true,
    },
    {
      Header: 'Remaining Steps',
      accessor: 'remainingSteps',
      filterable: true,
    },
    {
      Header: 'Total Redeemed Steps',
      accessor: 'totalRedeemedSteps',
      filterable: true,
    },
    {
      Header: 'Total Running Distance',
      accessor: 'totalRunningDistance',
      filterable: true,
      Cell: ({ value }) => {
        const distanceInKm = (value / 1000).toFixed(2);
        return `${value}m, ${distanceInKm}km`;
      }
    },
    {
      Header: 'Remaining Distance',
      accessor: 'remainingRunningDistance',
      filterable: true,
      Cell: ({ value }) => {
        const distanceInKm = (value / 1000).toFixed(2);
        return `${value}m, ${distanceInKm}km`;
      }
    },
    {
      Header: 'Total Redeemed Distance',
      accessor: 'totalRedeemedDistance',
      filterable: true,
      Cell: ({ value }) => {
        const distanceInKm = (value / 1000).toFixed(2);
        return `${value}m, ${distanceInKm}km`;
      }
    },
    {
      Header: 'Walking Trees',
      accessor: 'totalTreesBySteps',
      filterable: true,
    },
    {
      Header: 'Running Trees',
      accessor: 'totalTreesByRunning',
      filterable: true,
    },
    {
      Header: 'Like Count',
      accessor: 'userInfo.likeCount',
      filterable: true,
    },
    {
      Header: 'Joining Date',
      accessor: 'createdAt',
      filterable: true,
      Cell: ({ value }) => {
        const localDate = new Date(value).toLocaleString();
        return localDate;
      }
    },
    {
      Header: 'Last Updated',
      accessor: 'updatedAt',
      filterable: true,
      Cell: ({ value }) => {
        const localDate = new Date(value).toLocaleString();
        return localDate;
      }
    },
  ], []);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("All Users")}
            breadcrumbItem={props.t("All Users")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    {/* <h5 className="mb-0 card-title flex-grow-1">Add Admin</h5> */}
                    <div className="flex-shrink-0">
                      {/* <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-1">Add New </Link> */}
                      {/* <div className='btn me-1'> <AddModal /> </div>
                      <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link> */}
                      {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                        <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                          <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                        <DropdownMenu>
                          <li><DropdownItem href="#">Action</DropdownItem></li>
                          <li><DropdownItem href="#">Another action</DropdownItem></li>
                          <li><DropdownItem href="#">Something else here</DropdownItem></li>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={allUser}
                    isGlobalFilter={true}
                    // isJobListGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* add modal */}
          {/* <AddModal /> */}

        </Container>

      </div>
    </React.Fragment>
  );
};

const ImageCell = ({ cell: { value } }) => {
  // console.log(value, 'value ');
  return (
    <>
      {value ? (
        <img
          src={`${API_HOST}/${value}`}
          alt="User Image"
          style={{ width: '60px', height: '60px', borderRadius: 10, }}
          loading="lazy"
        />
      ) : (
        <span>No Image</span>
        // <img
        //     src="https://snap-booster.onrender.com/uploads/icon/icon-1707124734166.svg"
        //     alt="Icon" style={{ width: '30px', height: '30px' }}
        // />
      )}
    </>
  );
};

export default withTranslation()(SuperAdmin);