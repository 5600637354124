import React, { useState } from 'react'
import AdminContext from "./adminContext";
import axios from 'axios';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_ADMIN } from 'helpers/api_helper';

const AdminState = (props) => {
  // port
  // const host2 = process.env.REACT_APP_HOST || "http://localhost:8000"
  // const host = "http://localhost:8001/api/v2"
  // const API_URL = "http://localhost:8001"
  // const host = "http://192.168.29.19/api/v2"

  // live url
  // const host = process.env.REACT_APP_HOST
  const host = API_ADMIN
  const MobileHost = process.env.REACT_APP_API_APP
  const API_URL = process.env.REACT_APP_API_URL

  // error
  const [error, setError] = useState(null);

  // register / Signup  Api
  const Rigester = async (data) => {
    setError(null);
    // API Call        
    console.log(data.email, "email")
    localStorage.setItem("email", data.email);
    let response = await axios.post(`${host}/auth/register`, data)
      // let response = await axios.post(`${host2}/auth/register`, data)
      .then((response) => {
        console.log("Response", response);
        if (response.data.success) {
          //  localStorage.setItem("token", response.data.authtoken);
          localStorage.setItem("email", data.email);
          toast("Rigestered successfully Please Login!", {
            type: "success",
          });
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.error);
        // console.log("response",response)
        console.log('error', err)
        toast(err.response.data.error, {
          type: "error",
        });
        return false
      });
    return response
  };

  //  Login  Api
  const Login = async (data) => {
    setError(null);
    // API Call
    let response = await axios.post(`${host}/auth/login`, data)
      .then((response) => {
        //  console.log(".then",response);
        if (response.data.success) {
          // Save the auth token and redirect
          localStorage.setItem("token", response.data.authtoken);
          localStorage.setItem("id", response.data.user._id);
          localStorage.setItem("isAdmin", response.data.user.isAdmin);
          localStorage.setItem("role", response.data.user.role);
          // to show email in navbar
          // localStorage.setItem("email", response.data.email);  
          toast("Login Successfully", {
            type: "success",
            autoClose: 1000,
            theme: "light",
          });
          // end if
        }
        return true
      })
      .catch((err) => {
        // if (err && err.response) setError(err.response.data.message);
        if (err && err.response) setError(err.response.data.error);
        // alert(err.response.data.error)
        toast(err.response.data.error, {
          type: "error",
          autoClose: 3000,
          theme: "light",
        })
        console.log("error", err)
        return false
      });
    return response
  }
  // verify-otp

  const Verification = async (otp, email) => {

    let response = await axios.post(`${host}/auth/verify-email`, otp, email)
      .then((response) => {
        if (response.data.success === true) {
          toast("Email Verified Successfully ,Now You Can Login", {
            type: "success",
            autoClose: 3000,
            theme: "light",
          });

        }
        else if (response.data.success === false) {
          toast("OTP is not valid or didn't match", {
            type: "error",
            autoClose: 3000,
            theme: "light",
          });
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.Error);
        toast(err.response.data.Error && err.response.data, {
          type: "error",
          autoClose: 3000,
          theme: "light",
        })
        console.log("error", err)
        return false
      })
    return response
  }

  // state
  const [user, setUser] = useState('')
  // User Details Api
  const UserDetails = async () => {

    const response = await axios.post(`${host}/auth/userdetails`, {}, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    })
      .then((response) => {
        // console.log("response", response)
        // if (response.data.success){    
        //   const UserDetails = response.data.user
        //   setUser(response.data.user)
        //   setUser(response.data.user.username)
        //   console.log("userdetails",response.data.user)
        //     // localStorage.setItem("email", response.data.user.email);      
        //  }
        if (response.status === 200) {
          console.log("user", response.data.user)
          setUser(response.data.user)
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err?.response?.data?.error);
        console.log("error", err)
        toast(err?.response?.data?.error, {
          type: "error",
        });
        return false
      })
    return response
  }

  const ForgetPassword = async (email) => {
    let response = await axios.post(`${host}/auth/forget-password`, email)
      .then((response) => {
        if (response.data.success) {
          toast("Check Your Email ", {
            type: "success",
            autoClose: 3000,
            theme: "light",
          });
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.Error);
        toast(err.response.data.Error && err.response.data, {
          type: "error",
          autoClose: 3000,
          theme: "light",
        })
        console.log("error", err)
        return false
      })
    return response
  }

  const ResetPassword = async (password, token) => {
    const data = { password, token }
    let response = await axios.put(`${host}/auth/reset-password`, data)
      .then((response) => {
        if (response.data.success === true) {
          toast(
            "Password Updated Successfully "
            // , response.data.message
            ,
            {
              type: "success",
              autoClose: 3000,
              theme: "light",
            });
          return true
        }
        else {
          toast(
            "Token Expired  "
            // response.message
            ,
            {
              type: "error",
              autoClose: 3000,
              theme: "light",
            });
          return false
        }
        // return response
      })
      .catch((err) => {
        if (err && err.response)
          //  setError(err.response.data.Error);
          toast(err.response.message && err.response.err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
        console.log("error", err)
        return false
      })
    return response
  }

  const UpdateProfile = async (id, username, password, profile) => {
    console.log("id", id)
    const data = { username, password, profile }
    // console.log(data, "data")
    let response = await axios.put(`${host}/auth/update-profile/${id}`, data
      , {
        headers: {
          'Content-Type': 'multipart/form-data',
          // "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.data.success === true) {
          toast(
            " Updated Successfully ",
            {
              type: "success",
              autoClose: 3000,
              theme: "light",
            });
        }
        return true
      })
      .catch((err) => {
        if (err)
          // setError(err.response.data.err);
          toast(err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
        console.log("err", err)
        return false
      })
    return response
  }

  const [CountUser, setUserCount] = useState('')
  const UserCount = async () => {
    let response = await axios.get(`${host}/auth/userCount`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      }
    )
      .then((response) => {
        // console.log("res", response)
        if (response.status === 200) {
          console.log("user", response.data.userCount)
          setUserCount(response.data.userCount)
        }
      })
      .catch((err) => {
        console.log("err", err)
        if (err)
          toast(err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
      })
  }

  const [allUser, setAllUser] = useState([])
  const GetAllUser = async () => {
    try {
      let response = await axios.get(`${host}/auth/get-all-users-detail`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          }
        }
      )
        .then((response) => {
          console.log("res", response)
          if (response.status === 200) {
            // console.log("user", response)
            setAllUser(response.data.data)
          }
        })
        .catch((err) => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }
  const [categories, setAllcategories] = useState([])

  const GetAllCategories = async () => {
    try {
      let response = await axios.get(`${host}/category/get-all-categories`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            // console.log("category", response)
            setAllcategories(response.data.categories)
          }
        })
        .catch((err) => {
          // console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const AddCategory = async (categoryName, description, categoryIcon) => {
    // setError(null);
    // API Call        
    // const data = { categoryName, description, categoryIcon }
    console.log(categoryName, description, categoryIcon, "Add category")

    let response = await axios.post(`${host}/category/create-category`, categoryName, description, categoryIcon,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          // "Content-Type": "application/json",
          // Authorization: localStorage.getItem("token"),
        },
      }
    )

      .then((response) => {
        console.log("Response", response);
        if (response.data.success) {

          toast(" successfully!", {
            type: "success",
          });
        }
        return true
      })
      .catch((err) => {
        if (err && err.response) setError(err.response.data.error);
        // console.log("response",response)
        console.log('error', err)
        toast(err.response.data.error, {
          type: "error",

        });
        return false
      });
    return response
  };

  const UpdateCategory = async (id, username, password, profile) => {
    console.log("id", id)
    const data = { username, password, profile }
    // console.log(data, "data")
    let response = await axios.put(`${host}/api/v2/category/update-category/${id}`, data
      ,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          // "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    )
      .then((response) => {
        if (response.data.success === true) {
          toast(
            " Updated Successfully ",
            {
              type: "success",
              autoClose: 3000,
              theme: "light",
            });
        }
        return true
      })
      .catch((err) => {
        if (err)
          // setError(err.response.data.err);
          toast(err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
        console.log("err", err)
        return false
      })
    return response
  }

  const [services, setAllservices] = useState([])
  const GetAllServices = async () => {
    try {
      let response = await axios
        .get(
          `${host}/service/get-all-services-admin`
          // {
          //   headers: {
          //     Authorization: localStorage.getItem("token"),
          //   }
          // }
        )
        .then(response => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("services", response)
            setAllservices(response.data.services)
          }
        })
        .catch(err => {
          // console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [packages, setAllPackages] = useState([])
  const GetAllPackages = async () => {
    try {
      let response = await axios
        .get(
          `${host}/package/get-all-packages-admin`
          // {
          //   headers: {
          //     Authorization: localStorage.getItem("token"),
          //   }
          // }
        )
        .then(response => {
          // console.log("res", response)
          if (response.status === 200) {
            // console.log("packages", response)
            setAllPackages(response.data.packages)
          }
        })
        .catch(err => {
          // console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }
  const [orders, setOrders] = useState([])
  const GetAllOrders = async () => {
    try {
      let response = await axios
        .get(
          `${host}/orders/get-all-orders`
          // {
          //   headers: {
          //     Authorization: localStorage.getItem("token"),
          //   }
          // }
        )
        .then(response => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("orders", response)
            setOrders(response.data.orders)
          }
        })
        .catch(err => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [totalordersCount, setTotalOrderCount] = useState("")
  const TotalOrdersCount = async () => {
    let response = await axios
      .get(`${host}/auth/userCount`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(response => {
        // console.log("res", response)
        if (response.status === 200) {
          console.log("user", response.data.orderCount)
          setTotalOrderCount(response.data.orderCount)
        }
      })
      .catch(err => {
        console.log("err", err)
        if (err)
          toast(err, {
            type: "error",
            autoClose: 3000,
            theme: "light",
          })
      })
  }

  const [welcomeIntro, setwelcomeIntro] = useState([])
  const [howItWorks, setHowItWorks] = useState([])

  const GetIntroCMSData = async () => {
    try {
      let response = await axios.get(`${host}/cms/get-homepage-data`,
        // {
        //   headers: {
        //     Authorization: localStorage.getItem("token"),
        //   }
        // }
      )
        .then((response) => {
          // console.log("res", response)
          if (response.status === 200) {
            toast({
              type: "success",
              autoClose: 3000,
              theme: "light",
            })
            console.log("CMS Data", response.data)
            setwelcomeIntro(response.data.WelcomeData)
          }
        })
        .catch((err) => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  const [top10walkers, setTop10walkers] = useState([])
  const GetTop10Walkers = async () => {
    try {
      let response = await axios
        .get(
          `${MobileHost}/top10walkers`
          // {
          //   headers: {
          //     Authorization: localStorage.getItem("token"),
          //   }
          // }
        )
        .then(response => {
          // console.log("res", response)
          if (response.status === 200) {
            console.log("top 10 walkers", response)
            setOrders(response.data.users)
          }
        })
        .catch(err => {
          console.log("err", err)
          if (err)
            toast(err, {
              type: "error",
              autoClose: 3000,
              theme: "light",
            })
        })
    } catch (error) {
      console.log(error, "catch error")
    }
  }

  return (
    <AdminContext.Provider
      value={{
        API_URL, MobileHost,
        Rigester, Login, UserDetails, user, Verification, ForgetPassword,
        ResetPassword, UpdateProfile, UserCount, CountUser, GetAllUser, allUser,
        AddCategory, GetAllCategories, categories, GetAllServices, services, GetAllPackages, packages,
        GetAllOrders, orders, TotalOrdersCount, totalordersCount,
        GetIntroCMSData, welcomeIntro
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );


};

export default AdminState;
