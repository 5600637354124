import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';


import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

// import { deleteCategory } from "../../../helpers/smm_helper";
import AdminContext from '../../../context/adminContext';
import axios from 'axios';
import { API_APP } from "helpers/api_helper";

function LeaderManagement() {
    const context = useContext(AdminContext);
    const { orders, GetAllOrders, MobileHost } = context;

    const [top10walkers, setTop10walkers] = useState([])
    const GetTop10Walkers = async () => {
        try {
            let response = await axios
                .get(
                    `${API_APP}/top_10_walkers`
                    // {
                    //   headers: {
                    //     Authorization: localStorage.getItem("token"),
                    //   }
                    // }
                )
                .then(response => {
                    // console.log("res", response)
                    if (response.status === 200) {
                        // console.log("top 10 walkers", response)
                        setTop10walkers(response?.data?.users)
                    }
                })
                .catch(err => {
                    console.log("err", err)
                    if (err)
                        toast(err, {
                            type: "error",
                            autoClose: 3000,
                            theme: "light",
                        })
                })
        } catch (error) {
            console.log(error, "catch error")
        }
    }

    useEffect(() => {
        // GetAllOrders();
        GetTop10Walkers()
    }, []);

    const Mobilehost = process.env.REACT_APP_MOBILEHOST

    // port
    const host = process.env.REACT_APP_HOST
    //meta title
    document.title = "LeaderBoard ";

    const [modal, setModal] = useState(false);


    const [state, setState] = useState({
        editId: null,
        isLoading: false,
        delete_id: null,
        edit: null,
        status: '',
    })
    const {
        isLoading,
        delete_id,
    } = state

    const updateState = data => setState(state => ({ ...state, ...data }))

    const toggle = () => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    };

    const handleEditClick = arg => {
        // console.log(arg, 'arg')
        toggle();
        setIsEdit(true)
        // const data = arg;
        setFormState({
            editId: arg._id,
            // category: arg.category,   
            status: arg.status,
        })
        updateState({
            editId: arg._id,
            // category: arg.category,   
            status: arg.status,
        })
        // setIsEdit(true);
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: (row, index) => index + 1, // Auto-incremented number
                Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
                filterable: false,
            },
            // {
            //     Header: 'profile',
            //     accessor: 'profile',
            //     filterable: true,
            // },
            {
                Header: 'Username',
                accessor: 'username',
                filterable: true,
            },
            {
                Header: 'Total Steps',
                accessor: 'totalSteps',
                filterable: true,
            },
            {
                Header: 'Total Trees',
                accessor: 'totalTrees',
                filterable: true,
            },
            {
                Header: 'Full Name',
                accessor: 'full_name',
                filterable: true,
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: true,
            },
            {
                Header: 'Gender',
                accessor: 'gender',
                filterable: true,
            },
            {
                Header: 'State',
                accessor: 'state',
                filterable: true,
            },
            {
                Header: 'City',
                accessor: 'city',
                filterable: true,
            },
            {
                Header: 'Organization',
                accessor: 'organization',
                filterable: true,
            },
            // {
            //     Header: 'Action',
            //     accessor: '_id',
            //     disableFilters: true,
            //     Cell: (cellProps) => {
            //         const data = cellProps?.data?.find(elem => elem._id === cellProps.value)
            //         // console.log(data, 'data')
            //         // console.log(data._id)
            //         return (
            //             <ul className="list-unstyled hstack gap-1 mb-0">
            //                 {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
            //                     <Link to="#" className="btn btn-sm btn-soft-primary">
            //                         <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
            //                 </li>
            //                 <UncontrolledTooltip placement="top" target="viewtooltip">
            //                     ViewUncontrolledTooltip
            //                 </UncontrolledTooltip> */}
            //                 <li>
            //                     <Link
            //                         to="#"
            //                         className="btn btn-sm btn-soft-info"
            //                         // onClick={() => {
            //                         //     // const categoryData = cellProps.row.original;
            //                         //     // handleEditClick(categoryData);
            //                         // }}
            //                         onClick={() => {
            //                             setIsEdit(true)
            //                             // toggle();
            //                             setModal(true)
            //                             handleEditClick(data);
            //                             setFormState({
            //                                 editId: data._id,
            //                                 status: data.status,
            //                             })
            //                             setEditId(data._id)
            //                             // updateState({
            //                             //     isEdit: true,
            //                             //     // edit: data,
            //                             //     service: data.service, packageName: data.packageName,
            //                             // })
            //                         }}
            //                     >
            //                         <i className="mdi mdi-pencil-outline" id="edittooltip" />
            //                         <UncontrolledTooltip placement="top" target="edittooltip">
            //                             Edit
            //                         </UncontrolledTooltip>
            //                     </Link>
            //                 </li>
            //             </ul>
            //         );
            //     }
            // },
        ],
        []
    );

    const [formState, setFormState] = useState({
        editId: '',
        status: '',
    });

    const [validationErrors, setValidationErrors] = useState({});

    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('status', formState.status);
            const url = isEdit
                ? `${host}/orders/update-order-status/${editId}`
                : `${host}/orders/update-order-status/${editId}`;

            const response = await axios({
                method: isEdit ? 'put' : 'post',
                url,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.data.success === true) {
                // Reset form and perform other actions
                console.log(isEdit ? 'Updated package!' : 'Added package!');
                GetAllOrders();
                toast(`Successfully ${isEdit ? 'updated' : 'added'} Package!`, {
                    type: 'success',
                });
                toggle();
                setFormState({
                    status: '',
                });
                setIsEdit(false);
                setEditId(null);
            } else {
                toast(`Failed to ${isEdit ? 'update' : 'add'} Package`, {
                    type: 'error',
                });
            }
        } catch (error) {
            console.error(`Error ${isEdit ? 'updating' : 'creating'} Package:`, error.message);
            toast(`Error ${isEdit ? 'updating' : 'creating'} Package`, {
                type: 'error',
            });
        }
    };

    return (
        <React.Fragment>
            {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteService}
                onCloseClick={() => setDeleteModal(false)}
            /> */}
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="LeaderBoard"
                        breadcrumbItem="Top 10 Walkers"
                    />
                    <Row>
                        <Col lg="12">
                            {!isLoading && (
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex align-items-center">
                                            {/* <h5 className="mb-0 card-title flex-grow-1">Top 10 Walkers</h5> */}
                                            {/* <div className="flex-shrink-0">
                                                <Link to="#"
                                                    onClick={() => {
                                                        // console.log('add click')
                                                        setIsEdit(false)
                                                        setModal(true)
                                                    }
                                                        // updateState({ isEdit: true })
                                                    }
                                                    className="btn btn-primary me-1">Add Package
                                                </Link>
                                                <Link to="" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                                <UncontrolledDropdown className="dropdown d-inline-block me-1">

                                                </UncontrolledDropdown>
                                            </div> */}
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <TableContainer
                                            columns={columns}
                                            data={top10walkers}
                                            // data={dummyData}
                                            isGlobalFilter={true}
                                            isAddOptions={false}
                                            // handleJobClicks={handleJobClicks}
                                            // handleJobClicks={handleCategoryClicks}
                                            // isJobListGlobalFilter={true}
                                            customPageSize={10}
                                        />
                                        {top10walkers?.length == 0 && !isLoading && (
                                            <div className="p-5">
                                                <div className="text-center ">
                                                    <i
                                                        className="bx bx-search"
                                                        style={{ fontSize: "30px" }}
                                                    ></i>
                                                </div>
                                                <div className="text-center mt-3">No Users Found</div>
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {/* {!!isEdit ? "Edit Category" : "Add Category"} */}
                            {isEdit == true ? "Edit Order  Status" : "Add Package"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                // onSubmit={(e) => {
                                //     e.preventDefault();
                                //     console.log("object")
                                //     validation.handleSubmit();
                                //     return false;
                                // }}
                                onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col className="col-12">
                                        {/* <div className="mb-3">
                                            <Label className="form-label"> Choose Caregory</Label>
                                            <Input
                                                name="category"
                                                type="select"
                                                value={selectedCategory}
                                                // onChange={validation.handleChange}
                                                onChange={(e) => {
                                                    console.log(e.target.value, 'handleCategorySelect')
                                                    handleCategorySelect(e.target.value)
                                                }
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                {categories?.map(category => (
                                                    <option key={category._id} value={category._id}>
                                                        {category?.categoryName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div> */}

                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                value={formState.status}
                                                onChange={(e) =>
                                                    setFormState({ ...formState, status: e.target.value })
                                                }
                                            >
                                                <option value="" disabled>Select a service</option>
                                                <option value="Order Placed">Order Placed</option>
                                                <option value="Processing">Processing</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Refunded">Refunded</option>
                                            </Input>
                                            {validationErrors.status && (
                                                <div className="text-danger">{validationErrors.status}</div>
                                            )}
                                        </div>


                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            // onClick={onaddPackage()}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </React.Fragment >
    );
}


export default LeaderManagement;